import React from "react";

const Hero = () => {
  return (
    <div className="pt-20 pt-md-23 pt-lg-24">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-16"
              data-aos="zoom-in"
              data-aos-duration={200}
              data-aos-delay={100}
            >
              <h2 className="font-size-11 mb-5">About us</h2>
              <p className="font-size-7 mb-0">
                At Ocorie, we discover you, give you the relevant training and inspire you to soar higher and blossom into your full potential.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
